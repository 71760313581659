<template>
  <nuxt-link
    v-if="finalTo || action?.href"
    component-name="btn"
    :title="title"
    :aria-label="title"
    :data-size="size ? size : undefined"
    :data-pressed="pressed ? pressed : undefined"
    :data-disabled="disabled ? disabled : undefined"
    :tabindex="disabled ? '-1' : undefined"
    class="group/btn"
    :class="{
      '[&.router-link-exact-active]:!pointer-events-none': useRouterLinkClass,
    }"
    prefetched-class="prefetched"
    :to="finalTo || undefined"
    :href="action?.href ? getHref(action) : undefined"
    :target="target"
    :inert="disabled || pressed || undefined"
    :download="downloadName"
    @click="
      action?.href &&
      action?.cdn === 'thron' &&
      !rootStore.downloadsCopyrightDisclaimerCookie
        ? handleCopyrightDisclaimer($event)
        : undefined
    "
    @click.dataLayer="handleDataLayer"
    @click.reload="
      finalTo && finalTo === $route.path ? reloadSamePage() : undefined
    "
  >
    <slot />
    <!-- MODAL DOWNLOADS COPYRIGHT DISCLAIMER -->
    <template
      v-if="
        action?.href &&
        action?.cdn === 'thron' &&
        !rootStore.downloadsCopyrightDisclaimerCookie
      "
    >
      <client-only>
        <modal-copyright-disclaimer
          :is-open="openModalCopyrightDisclaimer"
          :action="action"
          @close="openModalCopyrightDisclaimer = false"
          @accept="handleDataLayer"
        />
      </client-only>
    </template>
    <!-- end MODAL DOWNLOADS COPYRIGHT DISCLAIMER -->
  </nuxt-link>

  <component
    v-else
    component-name="btn"
    :is="buttonTag"
    :title="title"
    :aria-label="title"
    :data-size="size ? size : undefined"
    :data-pressed="pressed ? pressed : undefined"
    :inert="disabled || pressed || undefined"
    class="cursor-pointer group/btn"
    :class="{
      'list-none': buttonTag === 'summary',
    }"
    @click.roomvo="action?.roomvoVisualizer ? openRoomvoVisualizer() : () => {}"
    @click.webToLead="openModalWebToLead = true"
    @click.dataLayer="handleDataLayer"
  >
    <slot />

    <!-- MODAL WEB TO LEAD -->
    <template v-if="action?.webToLead">
      <client-only>
        <modal-web-to-lead
          @close="openModalWebToLead = false"
          :is-open="openModalWebToLead"
        />
      </client-only>
    </template>
    <!-- end MODAL WEB TO LEAD -->
  </component>
</template>

<script setup>
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useRootStore } from "@/stores/root";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "regular", "big", "huge"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  action: {
    type: Object,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  pressed: {
    type: Boolean,
    required: false,
  },
  buttonTag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "summary"].indexOf(value) !== -1;
    },
    default: "button",
  },
  buttonType: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
  useRouterLinkClass: {
    type: Boolean,
    required: false,
    default: false,
  },
  useTracking: {
    type: [Boolean, Object],
    required: false,
    default: false,
  },
});

const route = useRoute();
const rootStore = useRootStore();
const nuxtApp = useNuxtApp();
const { fromThron, fromCloudinary } = useCdn();
const { pushDataLayer } = useDataLayer();
const openModalCopyrightDisclaimer = ref(false);
const openModalWebToLead = ref(false);

const finalTo = computed(() => {
  let to;
  if (props.action?.to) {
    if (
      typeof props.action.to === "object" &&
      props.action.to !== null &&
      !Array.isArray(props.action.to)
    ) {
      to = { ...props.action.to };
      if (to.path) {
        to.path = `/${route.params.country}/${route.params.locale}${to.path}`;
      }
    } else {
      to = `/${route.params.country}/${route.params.locale}${props.action.to}`;
    }
  }

  return to;
});

const target = computed(() => {
  const action = props.action;
  if (
    (downloadName.value && action.type === "image") ||
    (action?.href && action?.target === "_self")
  ) {
    return "_self";
  } else if (action?.href) {
    return action.tel || action.email ? undefined : "_blank";
  } else {
    return undefined;
  }
});

const downloadName = computed(() => {
  const action = props.action;
  return /thron|cloudinary/.test(action.cdn) && action.href
    ? action.filename || action.href
    : undefined;
});

function getHref(action) {
  if (action.cdn === "thron") {
    if (action.type === "image") {
      return `https://cms.mirage.it/download-image.php?url=${fromThron({
        contentId: action.href,
        prettyName: action.href,
        contentType: action.thronConfig?.contentType,
        divArea: {
          width: 1920,
          height: 0,
        },
        type: "image",
      })}&name=${action.filename || action.href}`;
    } else {
      return fromThron({
        filename: action.filename || action.href,
        id: action.href,
        type: "document",
      });
    }
  } else if (action.cdn === "cloudinary") {
    return `https://cms.mirage.it/download-image.php?url=${fromCloudinary(
      action.href,
      1920
    )}&name=${action.filename || action.href}`;
  } else if (action.tel === true) {
    return `tel:${parsePhoneNumberFromString(action.href)}`;
  } else if (action.email === true) {
    return `mailto:${action.href}`;
  }
  return action.href;
}

function handleCopyrightDisclaimer(event) {
  if (props.action.ignoreDisclaimer) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  event.stopImmediatePropagation();

  openModalCopyrightDisclaimer.value = true;
}

function openRoomvoVisualizer() {
  roomvo.startStandaloneVisualizer();
}

function reloadSamePage() {
  setTimeout(() => {
    nuxtApp.$lenis.scrollTo(0, {
      force: true,
      lock: true,
      duration: 0.55,
      easing: gsap.parseEase("power2.inOut"),
      onComplete: () => {
        ScrollTrigger.refresh(true);
      },
    });
  });

  // dispatch an event on window
  window.dispatchEvent(new Event("reload-same-page"));
}

function handleDataLayer() {
  let params = {};

  // CUSTOM PARAMS
  if (props.useTracking?.event) {
    params = structuredClone(props.useTracking);

    if (props.action?.to) {
      params.destination_url = props.action.to;
    } else if (
      props.action?.href &&
      props.action?.href === getHref(props.action)
    ) {
      params.destination_url = props.action.href;
    }
  }

  // EXTERNAL LINK
  else if (props.action?.href && props.action?.href === getHref(props.action)) {
    params.event = "outbound_link";
    params.destination_url = props.action.href;
  }

  // ROOMVO VISUALIVER
  else if (props.action?.roomvoVisualizer) {
    params.event = "click_configurator_roomvo";
  }

  // DOWNLOAD
  else if (
    props.action?.cdn &&
    downloadName.value &&
    rootStore.downloadsCopyrightDisclaimerCookie
  ) {
    params.event = "file_download_prodotto";
    params.file_download = downloadName.value;
  }

  // WEB TO LEAD
  else if (props.action?.webToLead) {
    params.event = "click_web_to_lead";
  }

  // PUSH TO DATA LAYER
  if (params.event) {
    pushDataLayer(params);
  }
}
</script>
