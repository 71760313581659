<template>
  <header
    component-name="app-header"
    ref="tpl_root"
    :data-skin="rootStore.appHeaderOnColor ? 'black' : 'white'"
    class="pointer-events-none"
  >
    <div
      class="flex flex-col items-stretch w-full border-b border-skin-base bg-skin-base will-change-[transform,color,background,border] transition-[transform,color,background,border] duration-300 ease-in-out"
      :class="{
        '!border-transparent !bg-transparent !bg-gradient-to-b !from-black/20 laptop:!from-black/60 !to-transparent bg-no-repeat bg-top':
          rootStore.appHeaderOnColor,
        '-translate-y-full': rootStore.appHeaderIsHidden,
        'pointer-events-auto': !rootStore.appHeaderIsHidden,
      }"
    >
      <!-- SECONDARY NAVIGATION -->
      <div
        data-skin="black"
        class="items-center justify-between hidden h-10 laptop:flex gap-md phablet:gap-2xl bg-skin-base px-edge"
      >
        <menu class="flex list-none gap-2xl">
          <li>
            <btn-nav
              size="small"
              :title="$t.labels.professional"
              @click="navToMenu('professional')"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'professional',
              }"
            />
          </li>

          <li>
            <btn-nav
              :title="$t.labels.resources"
              size="small"
              :action="{ to: '/resources' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'resources',
              }"
            />
          </li>

          <li>
            <btn-nav
              :title="$t.labels.press"
              size="small"
              :action="{ to: '/press' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'press',
              }"
            />
          </li>

          <li>
            <btn-nav
              :title="$t.labels.contacts"
              size="small"
              :action="{ to: '/contacts' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'contacts',
              }"
            />
          </li>
        </menu>

        <div class="hidden phablet:contents">
          <btn-nav
            :title="$t.labels.change_market"
            icon-left="world"
            icon-right="angle-down"
            size="small"
            @click="navToMenu('locale')"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'change_market_language',
            }"
          />
        </div>
      </div>
      <!-- end SECONDARY NAVIGATION -->

      <!-- PRIMARY NAVIGATION -->
      <nav
        class="flex gap-2xl justify-between items-center h-30 px-edge will-change-[height] transition-[height] duration-300 ease-in-out"
        :class="{
          '!h-20 delay-300': myWindowScrollY > hideOffset,
        }"
      >
        <btn-mirage class="text-skin-base" />

        <btn-icon
          class="laptop:hidden"
          size="big"
          :title="$t.labels.menu"
          icon="hamburger"
          @click="navToMenu('nav')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'hamburger',
          }"
        />

        <div class="items-center hidden laptop:flex gap-2xl">
          <btn-nav
            :title="$t.labels.products"
            @click="navToMenu('products')"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'products',
            }"
          />
          <btn-nav
            :title="$t.labels.attr_pa_soluzioni"
            @click="navToMenu('solutions')"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'solutions',
            }"
          />

          <btn-nav
            :title="$t.labels.atelier"
            :action="{ to: '/products/atelier' }"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'atelier',
            }"
          />

          <btn-nav
            :title="$t.labels.architects_and_designers"
            :action="{ to: '/architects-and-designers' }"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'architects and designers',
            }"
          />
          <btn-nav
            :title="$t.labels.customizers"
            @click="navToMenu('customizers')"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'customizers',
            }"
          />
          <btn-nav
            :title="$t.labels.stories"
            :action="{ to: '/stories/projects' }"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'stories',
            }"
          />
          <btn-nav
            :title="$t.labels.mirage_world"
            @click="navToMenu('mirage-world')"
            :use-tracking="{
              event: 'click_voce_menu',
              voce_menu: 'mirage world',
            }"
          />
          <div class="flex gap-lg">
            <btn-icon
              variant="naked"
              size="big"
              :title="$t.labels.search"
              icon="search"
              :action="{ to: '/search' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'search',
              }"
            />
            <btn-icon
              v-if="storeLocatorEnabled"
              variant="naked"
              size="big"
              :title="$t.labels.store_locator"
              icon="store-locator"
              :action="{ to: '/store-locator' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'store locator',
              }"
            />
            <btn-icon
              v-if="userStore.logged"
              variant="naked"
              size="big"
              :title="$t.labels.account"
              icon="user-logged"
              :action="{
                to: userStore.logged
                  ? '/account/personal-area/moodboards'
                  : '/signin',
              }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: userStore.logged ? 'moodboards' : 'signin',
              }"
            />
            <btn-icon
              v-else
              variant="naked"
              size="big"
              :title="$t.labels.signin"
              icon="user"
              :action="{ to: '/signin' }"
              :use-tracking="{
                event: 'click_voce_menu',
                voce_menu: 'signin',
              }"
            />
          </div>
        </div>
      </nav>
      <!-- end PRIMARY NAVIGATION -->
    </div>

    <!-- REFERENCE STATIC BAR -->
    <div
      inert
      class="z-10 absolute top-0 left-0 w-full flex flex-col items-stretch border-b border-skin-base bg-pink-500 !invisible"
    >
      <!-- REFERENCE SECONDARY NAVIGATION -->
      <div
        data-skin="black"
        class="items-center justify-between hidden h-10 bg-pink-900 laptop:flex gap-md phablet:gap-2xl px-edge"
      ></div>
      <!-- end REFERENCE SECONDARY NAVIGATION -->

      <!-- REFERENCE PRIMARY NAVIGATION -->
      <nav
        class="flex gap-2xl justify-between items-center h-30 px-edge will-change-[height] transition-[height] duration-300 ease-in-out"
        :class="{
          '!h-20 delay-300': myWindowScrollY > hideOffset,
        }"
      >
        <btn-mirage ref="tpl_btnMirage" class="text-pink-700" />
      </nav>
      <!-- end REFERENCE PRIMARY NAVIGATION -->
    </div>
    <!-- end REFERENCE STATIC BAR -->

    <!-- MODAL MENU -->
    <modal-menu
      :is-open="menuIsOpen"
      :navigation="menuNav"
      @navigation="navToMenu($event)"
      @close="menuIsOpen = false"
    />
    <!-- end MODAL MENU -->
  </header>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useRootStore } from "@/stores/root";

const tpl_root = templateRef("tpl_root");
const userStore = useUserStore();
const rootStore = useRootStore();
const { storeLocatorEnabled } = useGlobals();
const { y: windowScrollY } = useWindowScroll();
const menuIsOpen = ref(false);
const menuNav = ref("nav");
const tpl_btnMirage = templateRef("tpl_btnMirage");
const { y: btnMirageY } = useElementBounding(tpl_btnMirage);
const hideOffset = ref(160);
const latestScrollDown = ref();
const { height: windowH } = useWindowSize();
const { focused } = useFocusWithin(tpl_root);
const myWindowScrollY = ref(0);

watch(btnMirageY, (newVal) => {
  rootStore.setAppHeaderBtnMirageY(Math.floor(newVal));
});

function watchWindowScrollY(newVal, oldVal) {
  if (newVal > hideOffset.value) {
    if (
      oldVal &&
      newVal < latestScrollDown.value - parseInt((windowH.value / 100) * 20)
    ) {
      rootStore.setAppHeaderIsHidden(false);
    } else if (newVal > oldVal) {
      rootStore.setAppHeaderIsHidden(true);
      tpl_root.value.querySelector(":focus")?.blur();
    }

    useHead({
      htmlAttrs: {
        "data-app-header-minimized": true,
      },
    });
  } else {
    rootStore.setAppHeaderIsHidden(false);
    useHead({
      htmlAttrs: {
        "data-app-header-minimized": false,
      },
    });
  }

  latestScrollDown.value = newVal;
}

function navToMenu(target = "nav") {
  menuNav.value = target;
  menuIsOpen.value = true;
}

watch(
  () => rootStore.appHeaderIsHidden,
  (newVal, oldVal) => {
    useHead({
      htmlAttrs: {
        "data-app-header-hidden": newVal,
      },
    });
  }
);

watch(focused, (val) => {
  if (val) {
    rootStore.setAppHeaderIsHidden(false);
  }
});

onMounted(() => {
  myWindowScrollY.value = window.scrollY;
  watchWindowScrollY(myWindowScrollY.value);

  watchThrottled(
    () => windowScrollY.value,
    (newVal, oldVal) => {
      myWindowScrollY.value = newVal;
      watchWindowScrollY(newVal, oldVal);
    },
    {
      throttle: 200,
    }
  );
});
</script>
