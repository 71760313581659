<template>
  <div
    class="relative top-0 left-0 flex flex-col justify-between w-full h-auto max-w-full"
  >
    <span
      v-if="showInitialTabIndexEl"
      ref="tpl_initialTabIndex"
      @focus="blur()"
      @blur="showInitialTabIndexEl = false"
      tabindex="1"
      class="sr-only invisible"
      >tab-index-o</span
    >

    <a
      class="fixed top-0 left-[50%] z-50 bg-black/70 text-white p-4 text-body whitespace-nowrap w-min max-w-min -translate-x-1/2 opacity-0 pointer-events-none scale-90 focus:scale-100 focus:outline-none focus:shadow-none focus:pointer-events-auto focus:opacity-100 transition-[opacity,transform] duration-300 hover:underline rounded-b-lg origin-top"
      href="#main-content"
      :title="$t.labels.skip_to_main_content"
      :aria-label="$t.labels.skip_to_main_content"
      @click.prevent.stop="scrollToMain($event)"
      >{{ $t.labels.skip_to_main_content }}</a
    >

    <!-- MAIN -->
    <div class="flex flex-col w-full h-auto min-h-screen max-w-screen">
      <!-- HEADER -->
      <app-header class="fixed top-0 left-0 z-10 w-full" />
      <!-- end HEADER -->
      <!-- PAGE -->
      <main id="main-content" class="w-full">
        <nuxt-page
          :transition="{
            mode: 'out-in',
            css: false,
            onBeforeEnter: (el) => {},
            onEnter: onEnter,
            onAfterEnter: onAfterEnter,
            onEnterCancelled: (el) => {},
            onBeforeLeave: (el) => {},
            onLeave: onLeave,
            onAfterLeave: (el) => {},
            onLeaveCancelled: (el) => {},
          }"
        />
      </main>
      <!-- end PAGE -->

      <!-- PAGE CURTAIN -->
      <div
        v-show="showPageCurtain"
        aria-hidden="true"
        ref="tpl_pageCurtain"
        class="fixed inset-0 w-screen h-screen z-2 bg-skin-base"
      ></div>
      <!-- end PAGE CURTAIN -->
    </div>
    <!-- end MAIN -->

    <!-- FOOTER -->
    <app-footer class="w-full max-w-screen" />
    <!-- end FOOTER -->

    <!-- APP MODAL SPINNER -->
    <modal-spinner :is-open="appInert" class="z-3" />
    <!-- end APP MODAL SPINNER -->
  </div>

  <!-- INITIAL CURTAIN -->
  <transition-fade v-if="showInitialCurtain !== 'skip'" :use-appear="false">
    <div
      v-if="showInitialCurtain"
      class="fixed z-[9999] inset-0 w-screen h-screen bg-skin-base"
    ></div>
  </transition-fade>
  <!-- end INITIAL CURTAIN -->
</template>

<script setup>
import Lenis from "lenis";
import { useRootStore } from "@/stores/root";
import { useGeolistStore } from "@/stores/geolist";
import { useUserStore } from "@/stores/user";

const { isMobile } = useDevice();
const { storeLocatorEnabled, appInert, setAppInert } = useGlobals();

const tpl_initialTabIndex = templateRef("tpl_initialTabIndex");
const tpl_pageCurtain = templateRef("tpl_pageCurtain");
const rootStore = useRootStore();
const geolistStore = useGeolistStore();
const route = useRoute();
const router = useRouter();
const nuxtApp = useNuxtApp();
const showInitialCurtain = ref(true);
const { data } = await useFetch(`/api/locales?lng=${route.params.locale}`);
const showPageCurtain = ref(false);
const showInitialTabIndexEl = ref(false);
const { pushDataLayer } = useDataLayer();
let percentTracker = {
  25: false,
  50: false,
  75: false,
  90: false,
};

watch(showInitialTabIndexEl, (newVal, oldVal) => {
  if (newVal) {
    useSafeMountedEl(
      tpl_initialTabIndex,
      () => {
        tpl_initialTabIndex.value.focus();
        tpl_initialTabIndex.value.blur();
      },
      false
    );
  }
});

let lenis;

nuxtApp.provide("t", data.value);

router.beforeEach((to, from) => {
  if (to.path !== from.path) {
    setAppInert(true);
  }
});

router.afterEach((to, from) => {
  // sometimes the apps gets stuck in inert mode
  // this is a safety net to prevent that
  setTimeout(() => {
    console.log("afterEach");
    setAppInert(false);
    // remove the inert attribute from the body
    document.body.removeAttribute("inert");
  }, 2000);
});

let tl;

const onLeave = (el, done) => {
  if (tl) {
    tl.kill();
  }

  if (!rootStore.needsScrollReset) {
    done();
    return;
  }

  tl = gsap.timeline({
    onStart: () => {
      showPageCurtain.value = true;
    },
    onComplete: () => {
      done();
    },
  });

  tl.fromTo(
    tpl_pageCurtain.value,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.4,
      ease: "power2.inOut",
    }
  );
};

const onEnter = (el, done) => {
  showInitialTabIndexEl.value = true;

  percentTracker = {
    25: false,
    50: false,
    75: false,
    90: false,
  };

  done();
};

const onAfterEnter = (el) => {
  if (tl) {
    tl.kill();
  }

  if (!rootStore.needsScrollReset) {
    // back button navigation
    console.log("back button navigation");

    ScrollTrigger.refresh(true);
    showPageCurtain.value = false;
    rootStore.setNeedsScrollReset(true);
    setAppInert(false);
    return;
  }

  lenis.stop();

  tl = gsap.timeline({
    onStart: () => {
      ScrollTrigger.refresh(true);
    },
    onComplete: () => {
      showPageCurtain.value = false;
      rootStore.setNeedsScrollReset(true);
      lenis.start();
      setAppInert(false);

      console.log("onAfterEnter");
    },
  });

  tl.call(() => {
    tl.pause();
    if (lenis.scroll === 0) {
      ScrollTrigger.refresh();
      tl.resume();
    } else {
      lenis.scrollTo(0, {
        force: true,
        lock: true,
        immediate: true,
        onComplete: () => {
          ScrollTrigger.refresh();
          tl.resume();
        },
      });
    }
  });

  tl.to(
    tpl_pageCurtain.value,
    {
      opacity: 0,
      duration: 0.55,
      ease: "power2.inOut",
    },
    0.4
  );
};

const { getConfigs } = useIubenda();

provideHeadlessUseId(() => useId());

useHead({
  htmlAttrs: {
    "data-skin": "sand",
    lang: route.params.locale,
  },
  bodyAttrs: {
    inert: computed(() => appInert.value),
  },
  script: [
    {
      src: "https://cdn.roomvo.com/static/scripts/b2b/mirageit.js",
      type: "text/javascript",
      async: true,
    },
    {
      innerHTML: getConfigs(),
    },
    {
      src: "//cdn.iubenda.com/cs/gpp/stub.js",
      charset: "UTF-8",
      async: true,
    },
    {
      src: "//cdn.iubenda.com/cs/iubenda_cs.js",
      charset: "UTF-8",
      async: true,
    },
    {
      type: "application/ld+json",
      innerHTML: `{ "@context": "https://schema.org", "@type": "Organization", "url": "https://www.mirage.it", "logo": "https://www.mirage.it/logo.jpg" }`,
    },
  ],
});

await rootStore.loadUI();
await geolistStore.load();

function scrollToMain(evt) {
  evt.target.blur();

  nuxtApp.$lenis.scrollTo(0, {
    force: true,
    lock: true,
    offsetY: -1,
    duration: 0.4,
    easing: gsap.parseEase("power2.out"),
  });
}

onMounted(() => {
  if (isMobile) document.querySelector("html").classList.add("is-mobile");

  if (sessionStorage.getItem("skip-intro") === "true") {
    showInitialCurtain.value = "skip";
  } else {
    showInitialCurtain.value = false;
    sessionStorage.setItem("skip-intro", true);
  }

  // LENIS SCROLL

  lenis = new Lenis();

  lenis.on("scroll", (e) => {
    ScrollTrigger.update();
    const perc = Math.round(
      (window.scrollY / (e.dimensions.scrollHeight - window.innerHeight)) * 100
    );

    if (perc >= 90 && percentTracker[90] === false) {
      pushDataLayer({
        event: "scroll_90",
      });
      percentTracker[90] = true;
    } else if (perc >= 75 && percentTracker[75] === false) {
      pushDataLayer({
        event: "scroll_75",
      });
      percentTracker[75] = true;
    } else if (perc >= 50 && percentTracker[50] === false) {
      pushDataLayer({
        event: "scroll_50",
      });
      percentTracker[50] = true;
    } else if (perc >= 25 && percentTracker[25] === false) {
      pushDataLayer({
        event: "scroll_25",
      });
      percentTracker[25] = true;
    }
  });

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  nuxtApp.provide("lenis", lenis);

  ///////////////////////////////
  // Scroll blocker for modals //
  ///////////////////////////////

  const targetNode = document.querySelector("body");
  const config = { attributes: false, childList: true, subtree: false };
  let hasPortalRoot = false;
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        // get if id="headlessui-portal-root" is present in the body
        const portalRoot = document.getElementById("headlessui-portal-root");
        if (portalRoot && !hasPortalRoot) {
          lenis.stop();
          hasPortalRoot = true;
        } else if (hasPortalRoot && !portalRoot) {
          if (!showPageCurtain.value) {
            lenis.start();
          }
          hasPortalRoot = false;
        }
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);
});

const userStore = useUserStore();
if (!userStore.logged) {
  userStore
    .validate()
    .then(() => {})
    .catch((error) => {
      console.log("ERROR", error);
    });
}
</script>
